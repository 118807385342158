<template>
  <v-card flat class="pa-2">
    <v-autocomplete
      v-if="false"
      :items="matchupMatrix"
      item-text="name"
      return-object
      v-model="selectedMMs"
      label="Filter Teams"
      multiple chips small-chips deletable-chips
      attach solo
    ></v-autocomplete>
    <v-container fluid>
      <v-row dense>
        <v-col cols="12" md="6" lg="4">
          <v-sheet elevation="2">
            <div class="text-center title">As Teammates</div>
            <v-simple-table dense elevation-10>
              <template v-slot:default>
                <thead class="centered">
                  <tr>
                    <th>Team</th>
                    <th
                      v-for="n in seeds"
                      :key="n"
                    >
                      {{n}}
                    </th>
                  </tr>
                </thead>
                <tbody class="centered">
                  <tr
                    v-for="row in seeds"
                    :key="`r${row}`"
                  >
                    <td>{{row}}</td>
                    <td
                      v-for="col in seeds"
                      :key="`c${col}`"
                      :class="getMatchUps(row, col, false) === 0 ? 'grey--text text--lighten-3' : null"
                    >{{ getMatchUps(row, col, false) }}</td>
                  </tr>
                  <tr class="font-weight-bold">
                    <td>Total</td>
                    <td
                      v-for="col in seeds"
                      :key="`c${col}`"
                    >{{ getMatches(col) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-sheet elevation="2">
            <div class="text-center title">As Opponents</div>
            <v-simple-table dense elevation-10>
              <template v-slot:default>
                <thead class="centered">
                  <tr>
                    <th>Team</th>
                    <th
                      v-for="n in seeds"
                      :key="n"
                    >
                      {{n}}
                    </th>
                  </tr>
                </thead>
                <tbody class="centered">
                  <tr
                    v-for="row in seeds"
                    :key="`r${row}`"
                  >
                    <td>{{row}}</td>
                    <td
                      v-for="col in seeds"
                      :key="`c${col}`"
                      :class="getMatchUps(row, col, true) === 0 ? 'grey--text text--lighten-2' : null"
                    >{{ getMatchUps(row, col, true) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import firstBy from 'thenby'

export default {
  props: ['matches', 'division'],
  data () {
    return {
      selectedMMs: []
    }
  },
  computed: {
    seeds () {
      return this.division.activeTeams.map(m => m.seed).sort(firstBy(Number))
    },
    w () {
      return (this.seeds.length + 1) * 30
    }
  },
  methods: {
    getMatchUps (row, col, vs) {
      if (row === col) return null
      const t = this.division.activeTeams.find(f => f.seed === row)
      const ot = this.division.activeTeams.find(f => f.seed === col)
      return this.matches.filter(m => vs ? m.isBetweenTeamId2([t.id, ot.id]) : m.isWithTeamId([t.id, ot.id])).length
    },
    getMatches (col) {
      const t = this.division.activeTeams.find(f => f.seed === col)
      return this.matches.filter(m => m.activeTeamIds.includes(t.id)).length
    }
  }
}
</script>

<style scoped>
th,
td {
  text-align: center !important;
}
</style>
